import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {paymentListSetSearch, paymentListClearData, paymentListErrorClearData, paymentListClearSearch} from '../../../reducers/PaymentReducer/PaymentAction';
import { spinner } from '../../../reducers/UIReducer/UIAction';
import { showModal } from '../../../reducers/ModalReducer/ModalAction';
import AlertModal from '../../../components/AlertModal/AlertModal';
import paymentApi from '../../../api/paymentApi';

const useTop = (props) => {
  const {getCheck, activeTab, getListError} = props;
  const dispatch = useDispatch();
  const search = useSelector(({payment}) => payment.searchList);

  const onChange = (e) => {
    dispatch(paymentListSetSearch(e.target.value));
  };

  const clearValue = () => {
    dispatch(paymentListClearSearch());
    if(activeTab === 'home') {
      dispatch(paymentListClearData());
      getCheck(1, '');
    } else {
      dispatch(paymentListErrorClearData());
      getListError(1, '');
    }
  };

  const uploadFile = (file) => {
    dispatch(spinner(true));
    paymentApi.uploadCheck(file).then(({data}) => {
      dispatch(spinner(false));
      getCheck(1, '');
    }).catch(err => {
      dispatch(spinner(false));
      let msg = `Something wen't wrong`
      if (err) {
        if (err.data && err.data.data && err.data.data.message) {
          msg = err.data.data.message;
        }
        if (err.data && err.data.message) {
          msg = err.data.message;
        }
        dispatch(showModal(AlertModal, {title: msg}));
      }
    });
  }

  const exportFile = () => {
    dispatch(spinner(true));
    paymentApi.exportCheck().then(({data}) => {
      dispatch(spinner(false));
      const excelEl = document.createElement('a');
      excelEl.href = data.data.fileUrl;
      excelEl.click();
    }).catch(err => {
      dispatch(spinner(false));
      let msg = `Something wen't wrong`
      if (err) {
        if (err.data && err.data.message) {
          msg = err.data.message;
        }
        dispatch(showModal(AlertModal, {title: msg}));
      }
    });
  }

  return {
    search: {
      onChange: onChange,
      value: search,
      clearValue: clearValue
    },
    uploadFile,
    exportFile
  }
}

const useTab = () => {
  const [activeTab, setActiveTab] = useState('home');
  return {
    activeTab,
    setActiveTab
  }
}

export default {
  useTop,
  useTab
};
